<template>
  <div v-if="$props.image" class="image-container mb-10 w-full">
    <img :src="imageUrl" :alt="$props.image.alternativeText" :title="$props.image.caption" />
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
export default {
  name: "Image",
  props: ["image"],
  data() {
    return {
      imageUrl: '',
    }
  },
  created() {
    if(this.$props.image) {
      this.imageUrl = ApiService.getBaseUrl() + this.$props.image.url;
    }
  }
}
</script>